<template>
    <v-layout justify-center>
        <v-form @submit.prevent="UpdateProfile">
            <v-row justify="center">
              <v-avatar
                  v-if="user.data.photoURL"
                  size="150"
                  class="mt-10"
                >
                  <img
                    :alt="'user'"
                    :src="user.data.photoURL"
                  >
                </v-avatar>
            </v-row>
             <v-file-input
                prepend-icon="mdi-camera"
                v-model="file"
                hide-input
                class="avatarSelector"
              />
            <v-row class="mt-10">
              <label class="pt-5">Dein Vorname</label>
              <v-text-field
                v-if="user.loggedIn"
                v-model="userData.name"
                :placeholder="user.data.displayName"
                class="ml-10"
              />
            </v-row>
            <v-row justify="center">
              <v-btn large outlined color="#013333" class="mt-10" @click="GoHome">
                Zurück
              </v-btn>
              <v-btn type="submit" large outlined color="#013333" class="mt-10 ml-3">
                Speichern
              </v-btn>
            </v-row>
        </v-form>
    </v-layout>
</template>

<script>
import FileUploader from '../../uploader/FileUploader.ts'
import { mapGetters } from 'vuex'
import User from '../../user/User.ts'

export default {
  name: 'Profile',
  data () {
    return {
      userData: {
        name: '',
        file: null
      },
      preset: 'pwxz7wsv',
      results: null,
      file: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  methods: {
    UpdateProfile: function () {
      console.log('Update Profile called')
      // update whole user profile
      if (this.file && this.file.name && this.userData.name) {
        this.UpdateUserProfile(this.userData.name, this.file)
      } else if ( // update user name if changed
        this.user.data.displayName !== this.userData.name &&
        this.userData.name !== ''
      ) {
        const user = new User(this.user.data.displayName, this.user.data.email, this.user.data.uid)
        user.UpdateUserName(this.userData.name)
      } else if (this.file && this.file.name) {
        this.UpdateUserProfile(this.user.data.displayName, this.file)
      }
    },
    UpdateUserProfile: function (userName, file) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          // upload file to cloudinary
          const fileUploader = new FileUploader(this.preset)
          const requestObj = fileUploader.upload(reader)
          // upload changes to firebase
          const user = new User(this.user.data.displayName, this.user.data.email, this.user.data.uid)
          user.UpdateCurrentUserProfile(userName, requestObj)
        }.bind(this), false)
      reader.readAsDataURL(file) // call for file read if there is a file and update link in profile
    },
    GoHome: function () {
      this.$router.push('/')
    }
  }
}
</script>
<style>
.avatarSelector {
  margin-top: -60px;
  margin-left: 220px;
}
.avatarSelector .v-icon.v-icon {
    font-size: 50px !important;
}
.avatarSelector .theme--light.v-icon {
    color: rgba(0, 0, 0, 0.9);
}
</style>
